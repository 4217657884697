import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import TableContainer from '../../Components/Common/TableContainerReactTable';
import { getCustomers } from '../../slices/thunks';

const CustomerTable = () => {
    const dispatch: any = useDispatch();

    const customersData = createSelector(
        (state: any) => state.Customer,
        (state) => ({
            customers: state.customers,
            loading: state.loading,
        })
    );

    const { customers, loading } = useSelector(customersData);

    useEffect(() => {
        dispatch(getCustomers());
    }, [dispatch]);

    const columns = useMemo(
        () => [
            {
                header: 'ID',
                cell: (cell: any) => {
                    return (
                        <Link
                            to={`/customer/${cell.getValue()}`}
                            className="fw-medium"
                        >
                            {cell.getValue()}
                            {` `}
                            {cell.row.original.testUser ? (
                                <span className="badge bg-primary-subtle text-primary">
                                    {`Test`}
                                </span>
                            ) : (
                                ''
                            )}
                        </Link>
                    );
                },
                accessorKey: 'id',
                enableColumnFilter: false,
            },
            {
                header: 'Name',
                accessorKey: 'username',
                enableColumnFilter: false,
            },
            {
                header: 'Phone',
                accessorKey: 'phoneNumber',
                enableColumnFilter: false,
            },

            {
                header: 'Verified',
                accessorKey: 'verified',
                enableColumnFilter: false,
                cell: (cell: any) => {
                    if (cell.getValue()) {
                        return <span>true</span>;
                    } else {
                        return <span>false</span>;
                    }
                },
            },
            {
                header: 'Type',
                enableColumnFilter: false,
                accessorKey: 'type',
                cell: (cell: any) => {
                    switch (cell.getValue()) {
                        case 'admin':
                            return (
                                <span className="badge bg-info-subtle text-info">
                                    {cell.getValue()}
                                </span>
                            );
                        case 'guest':
                            return (
                                <span className="badge bg-light-subtle text-body">
                                    {cell.getValue()}
                                </span>
                            );
                    }
                },
            },
        ],
        []
    );

    return (
        <React.Fragment>
            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : (
                <TableContainer
                    columns={columns || []}
                    data={customers || []}
                    isGlobalFilter={true}
                    customPageSize={20}
                    divClass="table-responsive"
                    tableClass="table-sm table-centered align-middle table-nowrap mb-0"
                    theadClass="text-muted table-light"
                    SearchPlaceholder="Search..."
                />
            )}
        </React.Fragment>
    );
};

export { CustomerTable };
