import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, CardBody, CardHeader, Row, Col } from 'reactstrap';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { getAgents } from '../../../slices/thunks';

const Agent = () => {
    const { id } = useParams();
    const dispatch: any = useDispatch();

    const agentData = createSelector(
        (state: any) => state.Agent,
        (state) => ({
            agent: state.agents.find((agent: any) => agent.id === id),
        })
    );

    const { agent } = useSelector(agentData);

    useEffect(() => {
        if (!agent) {
            dispatch(getAgents());
        }
    }, [dispatch, agent]);

    document.title = 'Agent Summary | MySpy';

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        title={id}
                        breadcrumbs={[
                            { title: 'Dashboard', path: '/' },
                            { title: 'Agents', path: '/agents' },
                        ]}
                    />
                    <Card>
                        <CardHeader>
                            <b>Agent Summary for {id}</b>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                {agent &&
                                    Object.entries(agent).map(
                                        ([key, value]): any => (
                                            <Col
                                                xs="12"
                                                sm="6"
                                                lg="4"
                                                key={key}
                                                className="custom-col-3 mb-2"
                                            >
                                                <h6>
                                                    {key
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        key.slice(1)}
                                                </h6>
                                                <p>{`${value}`}</p>
                                            </Col>
                                        )
                                    )}
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Agent;
