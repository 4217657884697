interface ApiConfig {
    API_URL: string;
}

interface WSConfig {
    WS_URL: string;
}

interface Config {
    api: ApiConfig;
    ws: WSConfig;
}

const config: Config = {
    api: {
        API_URL: 'https://dev.myspy.cosvers.com/api/admin/',
    },
    ws: {
        WS_URL: 'wss://dev.myspy.cosvers.com/ws/',
    },
};

export default config;
