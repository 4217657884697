import { createSlice } from '@reduxjs/toolkit';

import { getCustomers, getCustomer } from './thunk';

export const initialState = {
    customers: [],
    loading: true,
    customer: {},
    customerLoading: true,
    error: {},
};

const customerSlice = createSlice({
    name: 'Customer',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(getCustomers.pending, (state: any) => {
            state.loading = true;
        });
        builder.addCase(getCustomers.fulfilled, (state: any, action: any) => {
            state.customers = action.payload;
            state.loading = false;
        });
        builder.addCase(getCustomers.rejected, (state: any, action: any) => {
            state.error = action.payload || null;
            state.loading = false;
        });

        builder.addCase(getCustomer.pending, (state: any) => {
            state.customerLoading = true;
        });
        builder.addCase(getCustomer.fulfilled, (state: any, action: any) => {
            state.customer = action.payload;
            state.customerLoading = false;
        });
        builder.addCase(getCustomer.rejected, (state: any, action: any) => {
            state.error = action.payload || null;
            state.customerLoading = false;
        });
    },
});

export default customerSlice.reducer;
