import { APIClient } from './api_helper';

import * as url from './url_helper';

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
    const user = localStorage.getItem('user');
    if (user) return JSON.parse(user);
    return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = (data: any) =>
    api.create(url.POST_FAKE_REGISTER, data);

// Login Method
export const postFakeLogin = (data: any) =>
    api.create(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = (data: any) =>
    api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data: any) =>
    api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data: any) =>
    api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);

// Register Method
export const postJwtRegister = (registerUrl: string, data: any) => {
    return api.create(registerUrl, data).catch((err) => {
        let message;
        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 404:
                    message =
                        'Sorry! the page you are looking for could not be found';
                    break;
                case 500:
                    message =
                        'Sorry! something went wrong, please contact our support team';
                    break;
                case 401:
                    message = 'Invalid credentials';
                    break;
                default:
                    message = err[1];
                    break;
            }
        }
        throw message;
    });
};

// Login Method
export const postJwtLogin = (data: any) =>
    api.create(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = (data: any) =>
    api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// Ecommerce
// get Products
export const getProducts = () => api.get(url.GET_PRODUCTS, '');

// delete Product
export const deleteProducts = (product: any) =>
    api.delete(url.DELETE_PRODUCT, { headers: { product } });

// add Products
export const addNewProduct = (product: any) =>
    api.create(url.ADD_NEW_PRODUCT, product);
// update Products
export const updateProduct = (product: any) =>
    api.update(url.UPDATE_PRODUCT, product);

// get Orders
export const getOrders = () => api.get(url.GET_ORDERS, '');

// add Order
export const addNewOrder = (order: any) => api.create(url.ADD_NEW_ORDER, order);

// update Order
export const updateOrder = (order: any) => api.update(url.UPDATE_ORDER, order);

// delete Order
export const deleteOrder = (order: any) =>
    api.delete(url.DELETE_ORDER, { headers: { order } });

// get Customers
export const getCustomers = () => api.get(url.GET_CUSTOMERS, '');

// add Customers
export const addNewCustomer = (customer: any) =>
    api.create(url.ADD_NEW_CUSTOMER, customer);

// update Customers
export const updateCustomer = (customer: any) =>
    api.update(url.UPDATE_CUSTOMER, customer);

// delete Customers
export const deleteCustomer = (customer: any) =>
    api.delete(url.DELETE_CUSTOMER, { headers: { customer } });

// CRM
// get Contacts
export const getContacts = () => api.get(url.GET_CONTACTS, '');

// add Contact
export const addNewContact = (contact: any) =>
    api.create(url.ADD_NEW_CONTACT, contact);

// update Contact
export const updateContact = (contact: any) =>
    api.update(url.UPDATE_CONTACT, contact);

// delete Contact
export const deleteContact = (contact: any) =>
    api.delete(url.DELETE_CONTACT, { headers: { contact } });

// Invoice
//get Invoice
export const getInvoices = () => api.get(url.GET_INVOICES, '');

// add Invoice
export const addNewInvoice = (invoice: any) =>
    api.create(url.ADD_NEW_INVOICE, invoice);

// update Invoice
export const updateInvoice = (invoice: any) =>
    api.update(url.UPDATE_INVOICE + '/' + invoice._id, invoice);

// delete Invoice
export const deleteInvoice = (invoice: any) =>
    api.delete(url.DELETE_INVOICE + '/' + invoice);

// Support Tickets
// Tickets
export const getTicketsList = () => api.get(url.GET_TICKETS_LIST, '');

// add Tickets
export const addNewTicket = (ticket: any) =>
    api.create(url.ADD_NEW_TICKET, ticket);

// update Tickets
export const updateTicket = (ticket: any) =>
    api.update(url.UPDATE_TICKET, ticket);

// delete Tickets
export const deleteTicket = (ticket: any) =>
    api.delete(url.DELETE_TICKET, { headers: { ticket } });

// File Manager

// Folder
export const getFolders = () => api.get(url.GET_FOLDERS, '');
export const deleteFolder = (folder: any) =>
    api.delete(url.DELETE_FOLDER, { headers: { folder } });
export const addNewFolder = (folder: any) =>
    api.create(url.ADD_NEW_FOLDER, folder);
export const updateFolder = (folder: any) => api.put(url.UPDATE_FOLDER, folder);

// File
export const getFiles = () => api.get(url.GET_FILES, '');
export const deleteFile = (file: any) =>
    api.delete(url.DELETE_FILE, { headers: { file } });
export const addNewFile = (file: any) => api.create(url.ADD_NEW_FILE, file);
export const updateFile = (file: any) => api.put(url.UPDATE_FILE, file);

//API Key
export const getAPIKey = () => api.get(url.GET_API_KEY, '');
