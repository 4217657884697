import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import QRCode from 'react-qr-code';

const AgentQRModal = ({ value, isOpen, toggle }: any) => {
    return (
        <Modal
            id="flipModal"
            size="sm"
            isOpen={isOpen}
            toggle={toggle}
            modalClassName="zoomIn"
            centered
        >
            <ModalHeader
                className="modal-title"
                id="flipModalLabel"
                toggle={toggle}
            ></ModalHeader>
            <ModalBody>
                <h5 className="fs-16 text-center pb-3">
                    Scan this QR to add your MySpy device.
                </h5>
                <div
                    style={{
                        height: 'auto',
                        margin: '0 auto',
                        maxWidth: 220,
                        width: '100%',
                        background: 'white',
                        padding: '6px',
                    }}
                >
                    <QRCode
                        size={256}
                        style={{
                            height: 'auto',
                            maxWidth: '100%',
                            width: '100%',
                        }}
                        value={value}
                        viewBox={`0 0 256 256`}
                    />
                </div>
            </ModalBody>
            <div className="modal-footer"></div>
        </Modal>
    );
};

export default AgentQRModal;
