import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    getCustomers as getCustomersApi,
    getCustomer as getCustomerApi,
} from '../../helpers/backend_helper';

export const getCustomers = createAsyncThunk(
    'customer/getCustomers',
    async () => {
        try {
            const response = getCustomersApi();
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const getCustomer = createAsyncThunk(
    'customer/getCustomer',
    async (customerId: string) => {
        try {
            const response = getCustomerApi(customerId);
            return response;
        } catch (error) {
            return error;
        }
    }
);
