import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
    Card,
    CardBody,
    Col,
    Container,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Input,
    Row,
    Spinner,
} from 'reactstrap';
import { Lightbox } from 'yet-another-react-lightbox';
// eslint-disable-next-line import/no-unresolved
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { getAlerts, getMoreAlerts } from '../../../slices/thunks';

const AgentAlerts = () => {
    const { id } = useParams();
    const dispatch: any = useDispatch();

    const [index, setIndex] = useState<any>(-1);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    const agentAlertsData = createSelector(
        (state: any) => state.Alert,
        (state) => ({
            alerts: state.alerts,
            loading: state.alertsLoading,
            canLoadMore: state.canLoadMore,
        })
    );

    const { alerts, loading, canLoadMore } = useSelector(agentAlertsData);

    useEffect(() => {
        dispatch(getAlerts({ agentId: id }));
    }, [dispatch, id]);

    const slideGallery = alerts.map((alert: any) => ({
        src: `data:image/*;base64,${alert.thumbnail}`,
    }));

    const toggledropDown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        const list = document.querySelectorAll('.alert-list');
        const buttonGroups = document.querySelectorAll('.filter-button');

        function onButtonGroupClick(event: any) {
            const target = event.target as HTMLButtonElement;
            const targetId = target.id;
            const parentTargetId = target.parentElement?.id;

            if (
                targetId === 'list-view-button' ||
                parentTargetId === 'list-view-button'
            ) {
                document
                    .getElementById('list-view-button')
                    ?.classList.add('active');
                document
                    .getElementById('grid-view-button')
                    ?.classList.remove('active');

                list.forEach((el) => {
                    el.classList.add('list-view-filter');
                    el.classList.remove('grid-view-filter');
                });
            } else {
                document
                    .getElementById('grid-view-button')
                    ?.classList.add('active');
                document
                    .getElementById('list-view-button')
                    ?.classList.remove('active');

                list.forEach((el) => {
                    el.classList.remove('list-view-filter');
                    el.classList.add('grid-view-filter');
                });
            }
        }

        for (let i = 0; i < buttonGroups.length; i++) {
            buttonGroups[i].addEventListener('click', onButtonGroupClick);
        }
    }, []);

    const searchList = (e: any) => {
        const inputVal = e.toLowerCase();

        const filterItems = (arr: any, query: any) => {
            return arr.filter((el: any) => {
                return (
                    el.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
                );
            });
        };

        const filterData = filterItems(alerts, inputVal);
        const noResultElement = document.getElementById('noresult');
        const teamListElement = document.getElementById('alertlist');

        if (filterData.length === 0) {
            if (noResultElement) {
                noResultElement.style.display = 'block';
            }
            if (teamListElement) {
                teamListElement.style.display = 'none';
            }
        } else {
            if (noResultElement) {
                noResultElement.style.display = 'none';
            }
            if (teamListElement) {
                teamListElement.style.display = 'block';
            }
        }
    };

    document.title = 'Alerts | MySpy';

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        title="Alerts"
                        breadcrumbs={[
                            { title: 'Dashboard', path: '/' },
                            { title: id, path: `/agent/${id}` },
                        ]}
                    />
                </Container>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" variant="primary" />
                    </div>
                ) : (
                    <div>
                        <Card>
                            <CardBody>
                                <Row className="g-2">
                                    <Col sm={4}>
                                        <div className="search-box">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search for alert type..."
                                                onChange={(e) =>
                                                    searchList(e.target.value)
                                                }
                                            />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </Col>
                                    <Col className="col-sm-auto ms-auto">
                                        <div className="list-grid-nav hstack gap-1">
                                            <Dropdown
                                                isOpen={dropdownOpen}
                                                toggle={toggledropDown}
                                            >
                                                <DropdownToggle
                                                    type="button"
                                                    className="btn btn-soft-info btn-icon fs-14"
                                                >
                                                    <i className="ri-more-2-fill"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <li>
                                                        <Link
                                                            className="dropdown-item"
                                                            to="#"
                                                        >
                                                            All
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="dropdown-item"
                                                            to="#"
                                                        >
                                                            Last Week
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="dropdown-item"
                                                            to="#"
                                                        >
                                                            Last Month
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="dropdown-item"
                                                            to="#"
                                                        >
                                                            Last Year
                                                        </Link>
                                                    </li>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Row>
                            <Col lg={12}>
                                <div id="alertlist">
                                    <Row className="alert-list grid-view-filter">
                                        {(alerts || []).map(
                                            (item: any, key: any) => (
                                                <Col
                                                    xxl={3}
                                                    xl={4}
                                                    sm={6}
                                                    className="element-item project designing development"
                                                    key={key}
                                                >
                                                    <Card className="gallery-box">
                                                        <div className="gallery-container">
                                                            <Link
                                                                className="image-popup"
                                                                to="#"
                                                                title={
                                                                    item.name
                                                                }
                                                                onClick={() =>
                                                                    setIndex(
                                                                        key
                                                                    )
                                                                }
                                                            >
                                                                {' '}
                                                                {item.thumbnail ? (
                                                                    <img
                                                                        className="gallery-img img-fluid mx-auto"
                                                                        src={`data:image/*;base64,${item.thumbnail}`}
                                                                        alt=""
                                                                    />
                                                                ) : null}
                                                                <div className="gallery-overlay">
                                                                    <h5 className="overlay-caption">
                                                                        {
                                                                            item.title
                                                                        }
                                                                    </h5>
                                                                </div>
                                                            </Link>
                                                        </div>

                                                        <div className="box-content">
                                                            <div className="d-flex align-items-center mt-1">
                                                                <div className="flex-grow-1 text-muted">
                                                                    <Link
                                                                        to="#"
                                                                        className="text-body text-truncate"
                                                                    >
                                                                        {
                                                                            item.created_at
                                                                        }
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            )
                                        )}
                                        {canLoadMore ? (
                                            <Col lg={12}>
                                                <div className="text-center mb-3">
                                                    <Link
                                                        to="#"
                                                        className="text-success"
                                                        onClick={() =>
                                                            dispatch(
                                                                getMoreAlerts({
                                                                    agentId: id,
                                                                })
                                                            )
                                                        }
                                                    >
                                                        <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>{' '}
                                                        Load More{' '}
                                                    </Link>
                                                </div>
                                            </Col>
                                        ) : null}
                                    </Row>
                                </div>
                                <div
                                    className="py-4 mt-4 text-center"
                                    id="noresult"
                                    style={{ display: 'none' }}
                                >
                                    <i className="ri-search-line display-5 text-success"></i>
                                    <h5 className="mt-4">
                                        Sorry! No Result Found
                                    </h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
            <Lightbox
                index={index}
                plugins={[Zoom]}
                slides={slideGallery}
                open={index >= 0}
                close={() => setIndex(-1)}
                zoom={{ maxZoomPixelRatio: 10 }}
            />
        </React.Fragment>
    );
};

export default AgentAlerts;
