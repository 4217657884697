import React from 'react';
import { Container, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { AlertTable } from './AlertTable';

const Dashboard = () => {
    document.title = 'Dashboard | MySpy';

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">Alerts</h5>
                                </CardHeader>
                                <CardBody className="pt-2">
                                    <AlertTable />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;
