import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
    createInvoice as createInvoiceApi,
    getInvoices as getInvoicesApi,
    getInvoice as getInvoiceApi,
    updateInvoice as updateInvoiceApi,
    deleteInvoice as deleteInvoiceApi,
} from '../../helpers/backend_helper';

export const createInvoice = createAsyncThunk(
    'invoice/createInvoice',
    async (invoiceData: any) => {
        try {
            const response = createInvoiceApi(invoiceData);
            toast.success('Created Invoice Successfully', { autoClose: 3000 });
            return response;
        } catch (error) {
            toast.error('Invoice Creation Failed', { autoClose: 3000 });
            return error;
        }
    }
);

export const getInvoices = createAsyncThunk('invoice/getInvoices', async () => {
    try {
        const response = getInvoicesApi();
        return response;
    } catch (error) {
        return error;
    }
});

export const getInvoice = createAsyncThunk(
    'invoice/getInvoice',
    async (invoiceId: string) => {
        try {
            const response = getInvoiceApi(invoiceId);
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const updateInvoice = createAsyncThunk(
    'invoice/updateInvoice',
    async ({ invoiceId, invoiceData }: any) => {
        try {
            const response = updateInvoiceApi(invoiceId, invoiceData);
            toast.success('Invoice Updated Successfully', { autoClose: 3000 });
            return response;
        } catch (error) {
            toast.error('Invoice Updation Failed', { autoClose: 3000 });
            return error;
        }
    }
);

export const deleteInvoice = createAsyncThunk(
    'invoice/deleteInvoice',
    async (invoiceId: string) => {
        try {
            const response = deleteInvoiceApi(invoiceId);
            toast.success('Invoice Deletion Successfull', { autoClose: 3000 });
            return response;
        } catch (error) {
            toast.error('Invoice Deletion Failed', { autoClose: 3000 });
            return error;
        }
    }
);
