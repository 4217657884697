import React, { useEffect } from 'react';
import {
    CardBody,
    Row,
    Col,
    Card,
    Table,
    CardHeader,
    Container,
    Spinner,
} from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { getInvoice } from '../../slices/thunks';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import logoDark from '../../assets/images/logo1-dark.png';
import logoLight from '../../assets/images/logo1-light.png';

const InvoiceDetails = () => {
    const { id } = useParams();

    const dispatch: any = useDispatch();

    const invoiceData = createSelector(
        (state: any) => state.Invoice,
        (state) => {
            let subTotal = 0;

            if (Array.isArray(state.invoice.amount)) {
                subTotal = state.invoice.amount
                    .map(
                        (item: number, index: number) =>
                            item * state.invoice.quantity[index]
                    )
                    .reduce((acc: number, curr: number) => acc + curr, 0);
            }

            const taxTotal =
                (subTotal - state.invoice.discount) *
                (state.invoice.taxPercentage / 100);

            return {
                invoice: state.invoice,
                loading: state.invoiceLoading,
                subTotal: subTotal,
                taxTotal: taxTotal,
                total: subTotal - state.invoice.discount + taxTotal,
            };
        }
    );

    const { invoice, loading, subTotal, taxTotal, total } =
        useSelector(invoiceData);

    useEffect(() => {
        if (id) {
            dispatch(getInvoice(id));
        }
    }, [dispatch, id]);

    //Print the Invoice
    const printInvoice = () => {
        window.print();
    };

    document.title = 'Invoice Details | MySpy';

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb
                    title={id}
                    breadcrumbs={[
                        { title: 'Dashboard', path: '/' },
                        { title: 'Invoices', path: '/invoices' },
                    ]}
                />

                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" variant="primary" />
                    </div>
                ) : (
                    <Row className="justify-content-center">
                        <Col xxl={9}>
                            <Card id="demo">
                                <Row>
                                    <Col lg={12}>
                                        <CardHeader className="border-bottom-dashed p-4">
                                            <div className="d-sm-flex">
                                                <div className="flex-grow-1">
                                                    <img
                                                        src={logoDark}
                                                        className="card-logo card-logo-dark"
                                                        alt="logo dark"
                                                        height="17"
                                                    />
                                                    <img
                                                        src={logoLight}
                                                        className="card-logo card-logo-light"
                                                        alt="logo light"
                                                        height="17"
                                                    />
                                                    <div className="mt-sm-5 mt-4">
                                                        <h6 className="text-muted text-uppercase fw-semibold">
                                                            Address
                                                        </h6>
                                                        <p
                                                            className="text-muted mb-1"
                                                            id="address-details"
                                                        >
                                                            {
                                                                invoice.companyAddress
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex-shrink-0 mt-sm-0 mt-3">
                                                    <h6>
                                                        <span className="text-muted fw-normal">
                                                            Email:
                                                        </span>{' '}
                                                        <span id="email">
                                                            {
                                                                invoice.companyEmail
                                                            }
                                                        </span>
                                                    </h6>
                                                    <h6>
                                                        <span className="text-muted fw-normal">
                                                            Website:
                                                        </span>{' '}
                                                        <Link
                                                            to="#"
                                                            className="link-primary"
                                                            id="website"
                                                        >
                                                            {
                                                                invoice.companySite
                                                            }
                                                        </Link>
                                                    </h6>
                                                    <h6>
                                                        <span className="text-muted fw-normal">
                                                            Contact No:
                                                        </span>{' '}
                                                        <span id="contact-no">
                                                            {
                                                                invoice.companyContactNo
                                                            }
                                                        </span>
                                                    </h6>
                                                    <h6 className="mb-0">
                                                        <span className="text-muted fw-normal">
                                                            GST Registration No:
                                                        </span>{' '}
                                                        <span id="legal-register-no">
                                                            {
                                                                invoice.companyGstNo
                                                            }
                                                        </span>
                                                    </h6>
                                                </div>
                                            </div>
                                        </CardHeader>
                                    </Col>
                                    <Col lg={12}>
                                        <CardBody className="p-4">
                                            <Row className="g-3">
                                                <Col lg={3} xs={6}>
                                                    <p className="text-muted mb-2 text-uppercase fw-semibold">
                                                        Invoice No
                                                    </p>
                                                    <h5 className="fs-14 mb-0">
                                                        <span id="invoice-no">
                                                            {invoice.id}
                                                        </span>
                                                    </h5>
                                                </Col>
                                                <Col lg={3} xs={6}>
                                                    <p className="text-muted mb-2 text-uppercase fw-semibold">
                                                        Date
                                                    </p>
                                                    <h5 className="fs-14 mb-0">
                                                        <span id="invoice-date">
                                                            {
                                                                invoice.invoiceDate
                                                            }
                                                        </span>
                                                    </h5>
                                                </Col>
                                                <Col lg={3} xs={6}>
                                                    <p className="text-muted mb-2 text-uppercase fw-semibold">
                                                        Payment Status
                                                    </p>
                                                    <span
                                                        className="badge bg-success-subtle text-success fs-11"
                                                        id="payment-status"
                                                    >
                                                        Paid
                                                    </span>
                                                </Col>
                                                <Col lg={3} xs={6}>
                                                    <p className="text-muted mb-2 text-uppercase fw-semibold">
                                                        Total Amount
                                                    </p>
                                                    <h5 className="fs-14 mb-0">
                                                        &#8377;
                                                        <span id="total-amount">
                                                            {total}
                                                        </span>
                                                    </h5>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Col>
                                    <Col lg={12}>
                                        <CardBody className="p-4 border-top border-top-dashed">
                                            <Row className="g-3">
                                                <Col sm={6}>
                                                    <h6 className="text-muted text-uppercase fw-semibold mb-3">
                                                        Billing Address
                                                    </h6>
                                                    <p
                                                        className="fw-medium mb-2"
                                                        id="billing-name"
                                                    >
                                                        {invoice.username}
                                                    </p>
                                                    <p
                                                        className="text-muted mb-1"
                                                        id="billing-address-line-1"
                                                    >
                                                        {invoice.billingAddress}
                                                    </p>
                                                    <p className="text-muted mb-1">
                                                        <span>Phone: </span>
                                                        <span id="billing-phone-no">
                                                            {
                                                                invoice.phoneNumber
                                                            }
                                                        </span>
                                                    </p>
                                                    <p className="text-muted mb-0">
                                                        <span>Tax: </span>
                                                        <span id="billing-tax-no">
                                                            12-3456789
                                                        </span>
                                                    </p>
                                                </Col>
                                                <Col sm={6}>
                                                    <h6 className="text-muted text-uppercase fw-semibold mb-3">
                                                        Shipping Address
                                                    </h6>
                                                    <p
                                                        className="fw-medium mb-2"
                                                        id="shipping-name"
                                                    >
                                                        {invoice.username}
                                                    </p>
                                                    <p
                                                        className="text-muted mb-1"
                                                        id="shipping-address-line-1"
                                                    >
                                                        {invoice.billingAddress}
                                                    </p>
                                                    <p className="text-muted mb-1">
                                                        <span>Phone: </span>
                                                        <span id="shipping-phone-no">
                                                            {
                                                                invoice.phoneNumber
                                                            }
                                                        </span>
                                                    </p>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Col>
                                    <Col lg={12}>
                                        <CardBody className="p-4">
                                            <div className="table-responsive">
                                                <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                                                    <thead>
                                                        <tr className="table-active">
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    width: '50px',
                                                                }}
                                                            >
                                                                #
                                                            </th>
                                                            <th scope="col">
                                                                Product Details
                                                            </th>
                                                            <th scope="col">
                                                                Rate
                                                            </th>
                                                            <th scope="col">
                                                                Quantity
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="text-end"
                                                            >
                                                                Amount
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="products-list">
                                                        {invoice.product &&
                                                            invoice.product.map(
                                                                (
                                                                    value: string,
                                                                    index: number
                                                                ) => {
                                                                    const amount =
                                                                        invoice
                                                                            .amount[
                                                                            index
                                                                        ];
                                                                    const quantity =
                                                                        invoice
                                                                            .quantity[
                                                                            index
                                                                        ];
                                                                    return (
                                                                        <tr
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <th scope="row">
                                                                                {index +
                                                                                    1}
                                                                            </th>
                                                                            <td className="text-start">
                                                                                <span className="fw-medium">
                                                                                    {
                                                                                        value
                                                                                    }
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                &#8377;
                                                                                {
                                                                                    amount
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    quantity
                                                                                }
                                                                            </td>
                                                                            <td className="text-end">
                                                                                &#8377;
                                                                                {amount *
                                                                                    quantity}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <div className="border-top border-top-dashed mt-2">
                                                <Table
                                                    className="table table-borderless table-nowrap align-middle mb-0 ms-auto"
                                                    style={{ width: '250px' }}
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td>Sub Total</td>
                                                            <td className="text-end">
                                                                &#8377;
                                                                {subTotal}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Discount{' '}
                                                                <small className="text-muted">
                                                                    (MYSPYNEW)
                                                                </small>
                                                            </td>
                                                            <td className="text-end">
                                                                - &#8377;
                                                                {
                                                                    invoice.discount
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Estimated Tax (
                                                                {
                                                                    invoice.taxPercentage
                                                                }
                                                                %)
                                                            </td>
                                                            <td className="text-end">
                                                                &#8377;
                                                                {taxTotal}
                                                            </td>
                                                        </tr>
                                                        <tr className="border-top border-top-dashed fs-15">
                                                            <th scope="row">
                                                                Total Amount
                                                            </th>
                                                            <th className="text-end">
                                                                &#8377;{total}
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <div className="mt-4">
                                                <div className="alert alert-info">
                                                    <p className="mb-0">
                                                        <span className="fw-semibold">
                                                            NOTES:
                                                        </span>
                                                        <span id="note">
                                                            {' '}
                                                            All payments for
                                                            invoices must be
                                                            made within a week
                                                            of receiving the
                                                            invoice. Payments
                                                            can be made through
                                                            cheque, credit card,
                                                            or online direct
                                                            payment. If the
                                                            payment is not made
                                                            within a week, the
                                                            credit details
                                                            provided as
                                                            confirmation for the
                                                            work done will be
                                                            charged the agreed
                                                            fee as quoted above.
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                                <Link
                                                    to="#"
                                                    onClick={printInvoice}
                                                    className="btn btn-success"
                                                >
                                                    <i className="ri-printer-line align-bottom me-1"></i>{' '}
                                                    Print
                                                </Link>
                                                <Link
                                                    to="#"
                                                    className="btn btn-primary"
                                                >
                                                    <i className="ri-download-2-line align-bottom me-1"></i>{' '}
                                                    Download
                                                </Link>
                                            </div>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Container>
        </div>
    );
};

export default InvoiceDetails;
