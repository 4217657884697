import { createSlice } from '@reduxjs/toolkit';

import {
    createInvoice,
    getInvoices,
    getInvoice,
    updateInvoice,
    deleteInvoice,
} from './thunk';

export const initialState = {
    invoices: [],
    loading: true,
    invoice: {},
    invoiceLoading: true,
    error: {},
};

const invoiceSlice = createSlice({
    name: 'Invoice',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(createInvoice.fulfilled, (state: any, action: any) => {
            state.invoices.push(action.payload);
        });
        builder.addCase(createInvoice.rejected, (state: any, action: any) => {
            state.error = action.payload || null;
        });

        builder.addCase(getInvoices.pending, (state: any) => {
            state.loading = true;
        });
        builder.addCase(getInvoices.fulfilled, (state: any, action: any) => {
            state.invoices = action.payload;
            state.loading = false;
        });
        builder.addCase(getInvoices.rejected, (state: any, action: any) => {
            state.error = action.payload || null;
            state.loading = false;
        });

        builder.addCase(getInvoice.pending, (state: any) => {
            state.invoiceLoading = true;
        });
        builder.addCase(getInvoice.fulfilled, (state: any, action: any) => {
            state.invoice = action.payload;
            state.invoiceLoading = false;
        });
        builder.addCase(getInvoice.rejected, (state: any, action: any) => {
            state.error = action.payload || null;
            state.invoiceLoading = false;
        });

        builder.addCase(updateInvoice.fulfilled, (state: any, action: any) => {
            state.invoices = state.invoices.map((invoice: any) =>
                invoice.id === action.payload.id ? action.payload : invoice
            );
        });
        builder.addCase(updateInvoice.rejected, (state: any, action: any) => {
            state.error = action.payload || null;
        });

        builder.addCase(deleteInvoice.fulfilled, (state: any, action: any) => {
            state.invoices = state.invoices.filter(
                (invoice: any) => invoice.id !== action.payload.id
            );
        });
        builder.addCase(deleteInvoice.rejected, (state: any, action: any) => {
            state.error = action.payload || null;
        });
    },
});

export default invoiceSlice.reducer;
