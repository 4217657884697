// Agents
export const GET_AGENTS = '/agent';
export const GET_AGENTS_LOGS = '/agent/logs';
export const UPDATE_AGENTS_NOTIFICATION_STATUS = '/agent/notification';
export const GET_ALERTS = '/agent/alert';

// User (or customer)
export const GET_USERS = '/user';

// Invoice
export const INVOICE = '/invoice';

//REGISTER
export const POST_FAKE_REGISTER = '/auth/signup';

//LOGIN
export const POST_FAKE_LOGIN = '/auth/signin';
export const POST_FAKE_JWT_LOGIN = '/post-jwt-login';
export const POST_FAKE_PASSWORD_FORGET = '/auth/forgot-password';
export const POST_FAKE_JWT_PASSWORD_FORGET = '/jwt-forget-pwd';

//PROFILE
export const POST_EDIT_JWT_PROFILE = '/post-jwt-profile';
export const POST_EDIT_PROFILE = '/user';

// Ecommerce
// Product
export const GET_PRODUCTS = '/product';
export const DELETE_PRODUCT = '/delete/product';
export const ADD_NEW_PRODUCT = '/add/product';
export const UPDATE_PRODUCT = '/update/product';

// Orders
export const GET_ORDERS = '/order';
export const ADD_NEW_ORDER = '/add/order';
export const UPDATE_ORDER = '/update/order';
export const DELETE_ORDER = '/delete/order';

// Customers
export const GET_CUSTOMERS = '/apps/customer';
export const ADD_NEW_CUSTOMER = '/apps/customer';
export const UPDATE_CUSTOMER = '/apps/customer';
export const DELETE_CUSTOMER = '/apps/customer';

// CRM
// Conatct
export const GET_CONTACTS = '/apps/contact';
export const ADD_NEW_CONTACT = '/apps/contact';
export const UPDATE_CONTACT = '/apps/contact';
export const DELETE_CONTACT = '/apps/contact';

// Invoice
export const GET_INVOICES = '/apps/invoice';
export const ADD_NEW_INVOICE = '/apps/invoice';
export const UPDATE_INVOICE = '/apps/invoice';
export const DELETE_INVOICE = '/apps/invoice';

// TicketsList
export const GET_TICKETS_LIST = '/ticket';
export const ADD_NEW_TICKET = '/add/ticket';
export const UPDATE_TICKET = '/update/ticket';
export const DELETE_TICKET = '/delete/ticket';

// File Manager
// Folder
export const GET_FOLDERS = '/folder';
export const DELETE_FOLDER = '/delete/folder';
export const ADD_NEW_FOLDER = '/add/folder';
export const UPDATE_FOLDER = '/update/folder';

// File
export const GET_FILES = '/file';
export const DELETE_FILE = '/delete/file';
export const ADD_NEW_FILE = '/add/file';
export const UPDATE_FILE = '/update/file';

//JOB APPLICATION
export const GET_API_KEY = '/api-key';
