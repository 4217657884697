import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import * as url from '../url_helper';
import { accessToken } from '../jwt-token-access/accessToken';

import {
    folderList,
    recentFile,
    apiKey,
    orders,
    customerList,
    crmcontacts,
    ticketsTable,
} from '../../common/data';

const users = [
    {
        uid: 1,
        role: 'admin',
        username: 'Admin',
        password: 'MySpyAdmin@123',
        email: 'admin@myspy.in',
        accessToken: '',
    },
];

const fakeBackend = () => {
    // This sets the mock adapter on the default instance
    const mock = new MockAdapter(axios, { onNoMatch: 'passthrough' });

    mock.onPost('/post-jwt-register').reply((config: any) => {
        const user = JSON.parse(config['data']);
        users.push(user);

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve([200, user]);
            });
        });
    });

    mock.onPost('/post-jwt-login').reply((config: any) => {
        const user = JSON.parse(config['data']);
        const validUser = users.filter(
            (usr) => usr.email === user.email && usr.password === user.password
        );

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (validUser['length'] === 1) {
                    // You have to generate AccessToken by jwt. but this is fakeBackend so, right now its dummy
                    const token = accessToken;

                    // JWT AccessToken
                    const tokenObj = { accessToken: token }; // Token Obj
                    const validUserObj = { ...validUser[0], ...tokenObj }; // validUser Obj
                    resolve([200, validUserObj]);
                } else {
                    reject([
                        400,
                        {
                            data: 'Username and password are invalid. Please enter correct username and password',
                        },
                    ]);
                }
            });
        });
    });

    mock.onPost('/post-jwt-profile').reply((config: any) => {
        const user = JSON.parse(config['data']);
        const one = config.headers;
        const finalToken = one.Authorization;
        const validUser = users.filter((usr) => usr.uid === user.idx);

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // Verify Jwt token from header.Authorization
                if (finalToken === accessToken) {
                    if (validUser['length'] === 1) {
                        //Find index of specific object using findIndex method.
                        const objIndex = users.findIndex(
                            (obj) => obj.uid === user.idx
                        );

                        //Update object's name property.
                        users[objIndex].username = user.username;
                        users[objIndex].accessToken = finalToken;

                        // Assign a value to locastorage
                        sessionStorage.removeItem('authUser');
                        sessionStorage.setItem(
                            'authUser',
                            JSON.stringify(users[objIndex])
                        );

                        resolve([
                            200,
                            {
                                status: 'Profile Updated Successfully',
                                data: users[objIndex],
                            },
                        ]);
                    } else {
                        reject([400, 'Something wrong for edit profile']);
                    }
                } else {
                    reject([400, 'Invalid Token !!']);
                }
            });
        });
    });

    // Ecommerce

    // Product
    mock.onPost(url.ADD_NEW_PRODUCT).reply((event: any) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (event && event.data) {
                    // Passing fake JSON data as response
                    resolve([200, event.data]);
                } else {
                    reject([400, 'Cannot add event']);
                }
            });
        });
    });

    mock.onPut(url.UPDATE_PRODUCT).reply((event: any) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (event && event.data) {
                    // Passing fake JSON data as response
                    resolve([200, event.data]);
                } else {
                    reject([400, 'Cannot update event']);
                }
            });
        });
    });

    mock.onPatch(url.UPDATE_PRODUCT).reply((event: any) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (event && event.data) {
                    // Passing fake JSON data as response
                    resolve([200, event.data]);
                } else {
                    reject([400, 'Cannot update event']);
                }
            });
        });
    });

    mock.onDelete(url.DELETE_PRODUCT).reply((config: any) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (config && config.headers) {
                    resolve([200, config.headers.data]);
                } else {
                    reject([400, 'Cannot delete event']);
                }
            });
        });
    });

    //ecommerce / orders
    mock.onGet(url.GET_ORDERS).reply(() => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (orders) {
                    // Passing fake JSON data as response
                    resolve([200, orders]);
                } else {
                    reject([400, 'Cannot get file data']);
                }
            });
        });
    });

    mock.onDelete(url.DELETE_ORDER).reply((config) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (config && config.headers) {
                    // Passing fake JSON data as response
                    resolve([200, config.headers.file]);
                } else {
                    reject([400, 'Cannot delete file data']);
                }
            });
        });
    });

    mock.onPost(url.ADD_NEW_ORDER).reply((file) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (file && file.data) {
                    // Passing fake JSON data as response
                    resolve([200, file.data]);
                } else {
                    reject([400, 'Cannot add file data']);
                }
            });
        });
    });

    mock.onPut(url.UPDATE_ORDER).reply((file) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (file && file.data) {
                    // Passing fake JSON data as response
                    resolve([200, file.data]);
                } else {
                    reject([400, 'Cannot update file data']);
                }
            });
        });
    });

    mock.onPatch(url.UPDATE_ORDER).reply((event: any) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (event && event.data) {
                    // Passing fake JSON data as response
                    resolve([200, event.data]);
                } else {
                    reject([400, 'Cannot update event']);
                }
            });
        });
    });

    // Ecommerce / Customer
    mock.onGet(url.GET_CUSTOMERS).reply(() => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (customerList) {
                    // Passing fake JSON data as response
                    resolve([200, customerList]);
                } else {
                    reject([400, 'Cannot get file data']);
                }
            });
        });
    });

    mock.onDelete(url.DELETE_CUSTOMER).reply((config) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (config && config.headers) {
                    // Passing fake JSON data as response
                    resolve([200, config.headers.file]);
                } else {
                    reject([400, 'Cannot delete file data']);
                }
            });
        });
    });

    mock.onPost(url.ADD_NEW_CUSTOMER).reply((file) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (file && file.data) {
                    // Passing fake JSON data as response
                    resolve([200, file.data]);
                } else {
                    reject([400, 'Cannot add file data']);
                }
            });
        });
    });

    mock.onPut(url.UPDATE_CUSTOMER).reply((file) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (file && file.data) {
                    // Passing fake JSON data as response
                    resolve([200, file.data]);
                } else {
                    reject([400, 'Cannot update file data']);
                }
            });
        });
    });

    mock.onPatch(url.UPDATE_CUSTOMER).reply((event: any) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (event && event.data) {
                    // Passing fake JSON data as response
                    resolve([200, event.data]);
                } else {
                    reject([400, 'Cannot update event']);
                }
            });
        });
    });

    // Crm Contact
    mock.onGet(url.GET_CONTACTS).reply(() => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (crmcontacts) {
                    // Passing fake JSON data as response
                    resolve([200, crmcontacts]);
                } else {
                    reject([400, 'Cannot get file data']);
                }
            });
        });
    });

    mock.onDelete(url.DELETE_CONTACT).reply((config) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (config && config.headers) {
                    // Passing fake JSON data as response
                    resolve([200, config.headers.file]);
                } else {
                    reject([400, 'Cannot delete file data']);
                }
            });
        });
    });

    mock.onPost(url.ADD_NEW_CONTACT).reply((file) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (file && file.data) {
                    // Passing fake JSON data as response
                    resolve([200, file.data]);
                } else {
                    reject([400, 'Cannot add file data']);
                }
            });
        });
    });

    mock.onPut(url.UPDATE_CONTACT).reply((file) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (file && file.data) {
                    // Passing fake JSON data as response
                    resolve([200, file.data]);
                } else {
                    reject([400, 'Cannot update file data']);
                }
            });
        });
    });

    mock.onPatch(url.UPDATE_CONTACT).reply((event: any) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (event && event.data) {
                    // Passing fake JSON data as response
                    resolve([200, event.data]);
                } else {
                    reject([400, 'Cannot update event']);
                }
            });
        });
    });

    // Support Ticket

    mock.onGet(url.GET_TICKETS_LIST).reply(() => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (ticketsTable) {
                    // Passing fake JSON data as response
                    resolve([200, ticketsTable]);
                } else {
                    reject([400, 'Cannot get file data']);
                }
            });
        });
    });

    mock.onDelete(url.DELETE_TICKET).reply((config) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (config && config.headers) {
                    // Passing fake JSON data as response
                    resolve([200, config.headers.file]);
                } else {
                    reject([400, 'Cannot delete file data']);
                }
            });
        });
    });

    mock.onPost(url.ADD_NEW_TICKET).reply((file) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (file && file.data) {
                    // Passing fake JSON data as response
                    resolve([200, file.data]);
                } else {
                    reject([400, 'Cannot add file data']);
                }
            });
        });
    });

    mock.onPut(url.UPDATE_TICKET).reply((file) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (file && file.data) {
                    // Passing fake JSON data as response
                    resolve([200, file.data]);
                } else {
                    reject([400, 'Cannot update file data']);
                }
            });
        });
    });

    mock.onPatch(url.UPDATE_TICKET).reply((event: any) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (event && event.data) {
                    // Passing fake JSON data as response
                    resolve([200, event.data]);
                } else {
                    reject([400, 'Cannot update event']);
                }
            });
        });
    });

    // File Manager
    // Folder
    mock.onGet(url.GET_FOLDERS).reply(() => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (folderList) {
                    // Passing fake JSON data as response
                    resolve([200, folderList]);
                } else {
                    reject([400, 'Cannot get folder data']);
                }
            });
        });
    });

    mock.onDelete(url.DELETE_FOLDER).reply((config) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (config && config.headers) {
                    // Passing fake JSON data as response
                    resolve([200, config.headers.folder]);
                } else {
                    reject([400, 'Cannot delete folder data']);
                }
            });
        });
    });

    mock.onPost(url.ADD_NEW_FOLDER).reply((folder) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (folder && folder.data) {
                    // Passing fake JSON data as response
                    resolve([200, folder.data]);
                } else {
                    reject([400, 'Cannot add folder data']);
                }
            });
        });
    });

    mock.onPut(url.UPDATE_FOLDER).reply((folder) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (folder && folder.data) {
                    // Passing fake JSON data as response
                    resolve([200, folder.data]);
                } else {
                    reject([400, 'Cannot update folder data']);
                }
            });
        });
    });

    // File
    mock.onGet(url.GET_FILES).reply(() => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (recentFile) {
                    // Passing fake JSON data as response
                    resolve([200, recentFile]);
                } else {
                    reject([400, 'Cannot get file data']);
                }
            });
        });
    });

    mock.onDelete(url.DELETE_FILE).reply((config) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (config && config.headers) {
                    // Passing fake JSON data as response
                    resolve([200, config.headers.file]);
                } else {
                    reject([400, 'Cannot delete file data']);
                }
            });
        });
    });

    mock.onPost(url.ADD_NEW_FILE).reply((file) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (file && file.data) {
                    // Passing fake JSON data as response
                    resolve([200, file.data]);
                } else {
                    reject([400, 'Cannot add file data']);
                }
            });
        });
    });

    mock.onPut(url.UPDATE_FILE).reply((file) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (file && file.data) {
                    // Passing fake JSON data as response
                    resolve([200, file.data]);
                } else {
                    reject([400, 'Cannot update file data']);
                }
            });
        });
    });

    // Invoice
    mock.onDelete(url.DELETE_INVOICE).reply((config: any) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (config && config.headers) {
                    resolve([200, config.headers.invoice]);
                } else {
                    reject([400, 'Cannot delete event']);
                }
            });
        });
    });

    //API Key
    mock.onGet(url.GET_API_KEY).reply(() => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (apiKey) {
                    // Passing fake JSON data as response
                    resolve([200, apiKey]);
                } else {
                    reject([400, 'Cannot get API Key Data']);
                }
            });
        });
    });
};

export default fakeBackend;
