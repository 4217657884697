import { createSlice } from '@reduxjs/toolkit';
//constants
import {
    layoutTypes,
    layoutModeTypes,
    leftsidbarSizeTypes,
    leftsidebarHover,
    preloaderTypes,
    sidebarVisibilitytypes,
} from '../../Components/constants/layout';

export const initialState = {
    layoutType: layoutTypes.VERTICAL,
    layoutModeType: layoutModeTypes.LIGHTMODE,
    leftsidbarSizeType: leftsidbarSizeTypes.SMALLHOVER,
    leftsidebarHover: leftsidebarHover.ACTIVE,
    preloader: preloaderTypes.DISABLE,
    sidebarVisibilitytype: sidebarVisibilitytypes.SHOW,
};

const LayoutSlice = createSlice({
    name: 'LayoutSlice',
    initialState,
    reducers: {
        changeLayoutAction(state: any, action: any) {
            state.layoutType = action.payload;
        },
        changeLayoutModeAction(state: any, action: any) {
            state.layoutModeType = action.payload;
        },
        changeSidebarHoverAction(state: any, action: any) {
            state.leftsidebarHover = action.payload;
        },
        changePreLoaderAction(state: any, action: any) {
            state.preloader = action.payload;
        },
        changeSidebarVisibilityAction(state: any, action: any) {
            state.sidebarVisibilitytype = action.payload;
        },
    },
});

export const {
    changeLayoutAction,
    changeLayoutModeAction,
    changeSidebarHoverAction,
    changePreLoaderAction,
    changeSidebarVisibilityAction,
} = LayoutSlice.actions;

export default LayoutSlice.reducer;
