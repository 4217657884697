import { APIClient } from './api_helper';

import * as url from './url_helper';

const api = new APIClient();

// Agents
export const getAgents = () => api.get(url.GET_AGENTS, '');

export const getAgentLogsData = (agentId: string, logsType: string) =>
    api.get(url.GET_AGENTS_LOGS + '/' + agentId, { type: logsType });

export const getAgentLogs = (
    agentId: string,
    logsType: string,
    logsName: string
) =>
    api.get(url.GET_AGENTS_LOGS + '/' + agentId + '/' + logsName, {
        type: logsType,
    });

export const setNotificationStatus = (agentId: string, status: boolean) =>
    api.get(url.UPDATE_AGENTS_NOTIFICATION_STATUS + '/' + agentId, {
        status: status,
    });

export const getAlerts = (
    agentId: string,
    afterId: string = '',
    alertType: string = '',
    sortBy: string = ''
) =>
    api.get(url.GET_ALERTS + '/' + agentId, {
        alertType: alertType,
        sortBy: sortBy,
        afterId: afterId,
    });

// Customers
export const getCustomers = () => api.get(url.GET_USERS, '');

export const getCustomer = (customerId: string) =>
    api.get(url.GET_USERS + '/' + customerId, '');

// Invoice
export const createInvoice = (invoiceData: any) =>
    api.create(url.INVOICE, invoiceData);

export const getInvoices = () => api.get(url.INVOICE, '');

export const getInvoice = (invoiceId: string) =>
    api.get(url.INVOICE + '/' + invoiceId, '');

export const updateInvoice = (invoiceId: string, invoiceData: any) =>
    api.put(url.INVOICE + '/' + invoiceId, invoiceData);

export const deleteInvoice = (invoiceId: string) =>
    api.delete(url.INVOICE + '/' + invoiceId);
