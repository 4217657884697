// https://medium.com/globant/improving-code-quality-in-react-with-eslint-prettier-and-typescript-86635033d803

import React from 'react';

//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes';

// Fackbackend
import fakeBackend from './helpers/AuthType/fakeBackend';

// Activating fake backend
fakeBackend();

function App(): any {
    return (
        <React.Fragment>
            <Route />
        </React.Fragment>
    );
}

export default App;
